export const WORKFLOW_CREATED = 'CREATED';
export const WORKFLOW_DESIGN = 'DESIGN';
export const WORKFLOW_RUNNING = 'RUNNING';
export const WORKFLOW_PAUSED = 'PAUSED';
export const WORKFLOW_STOPPED = 'STOPPED';
export const WORKFLOW_COMPLETED = 'SUCCESS';
export const WORKFLOW_ERROR = 'FAILURE';
export const NODE_CREATED = 'CREATED';
export const NODE_QUEUED = 'READY';
export const NODE_RUNNING = 'RUNNING';
export const NODE_STOPPED = 'STOPPED';
export const NODE_PAUSED = 'PAUSED';
export const NODE_COMPLETED = 'SUCCESS';
export const NODE_ERROR = 'FAILURE';
export const RUN_CREATED = 'CREATED';
export const RUN_RUNNING = 'RUN_RUNNING';
export const RUN_COMPLETED = 'RUN_COMPLETED';
export const RUN_ERROR = 'RUN_ERROR';
export const RESOURCE_WORKFLOW = 'Workflow';
export const RESOURCE_RUN = 'Run';
export const NODE_CATEGORY_RESOURCES = 'RESOURCES';
export const NODE_CATEGORY_DATA_VISUALIZATION = 'DATA_VISUALIZATION';
export const NODE_CATEGORY_WELL_CONDITIONING = 'WELL_CONDITIONING';
export const NODE_CATEGORY_FEATURE_ENGINEERING = 'FEATURE_ENGINEERING';
export const NODE_CATEGORY_MODEL_DEVELOPMENT = 'MODEL_DEVELOPMENT';
export const NODE_CATEGORY_MODEL_UTILIZATION = 'MODEL_UTILIZATION';
export const NODE_CATEGORY_DATA_CONDITIONING = 'DATA_CONDITIONING';
export const NODE_CATEGORY_MODELING = 'MODELING';
export const NODE_CATEGORY_QLOG_NODES = 'QLOG_NODES';
export const NODE_CATEGORY_QDRILL_NODES = 'QDRILL_NODES';

/*
    NODE TYPES
*/
export const NODE_TYPES = {
  ATTACH_DEVIATION: 'ATTACH_DEVIATION',
  COMPILE_WELL_BASE: 'COMPILE_WELL_BASE',
  CREATE_DATA_MODEL: 'CREATE_DATA_MODEL',
  CREATE_HORIZON: 'CREATE_HORIZON',
  CREATE_SEISMIC: 'CREATE_SEISMIC',
  BUlK_CREATE_SEISMIC: 'BULK_CREATE_SEISMIC',
  CREATE_WELL: 'CREATE_WELL',
  DEFINE_MODEL_ARCHITECTURE: 'DEFINE_MODEL_ARCHITECTURE',
  LOAD_DEPTH_TIME_CALIBRATION: 'LOAD_DEPTH_TIME_CALIBRATION',
  ENSEMBLE_SIMULATIONS: 'ENSEMBLE_SIMULATIONS',
  EXPORT_SIMULATIONS: 'EXPORT_SIMULATIONS',
  FINALIZE_TRAINING_DATA: 'FINALIZE_TRAINING_DATA',
  GENERATE_FEATURES_HORIZONS: 'GENERATE_FEATURES_HORIZONS',
  GENERATE_FEATURES_SEISMIC: 'GENERATE_FEATURES_SEISMIC',
  HYPER_PARAMETER_EXPLORER: 'HYPER_PARAMETER_EXPLORER',
  SET_ADVANCED_TRAINING_CONFIGURATIONS: 'SET_ADVANCED_TRAINING_CONFIGURATIONS',
  SET_WELL_METADATA: 'SET_WELL_METADATA',
  SENSITIVITY_ANALYSIS: 'SENSITIVITY_ANALYSIS',
  SIMULATE_SEISMIC: 'SIMULATE_SEISMIC',
  SIMULATE_WELLS: 'SIMULATE_WELLS',
  TRAIN_MODEL: 'TRAIN_MODEL',
  VISUALIZE_SEISMIC: 'VISUALIZE_SEISMIC',
  WELL_PROCESS_FOR_MODELING: 'WELL_PROCESS_FOR_MODELING',
  WELL_RESAMPLE: 'WELL_RESAMPLE',
  ENSEMBLE_WELL_OVERLAY: 'ENSEMBLE_WELL_OVERLAY',
  QLOG_INGESTION: 'QLOG_INGESTION',
  QLOG_FEATURE_ENGINEERING: 'QLOG_FEATURE_ENGINEERING',
  QLOG_TRAIN_ENSEMBLE: 'QLOG_TRAIN_ENSEMBLE',
  QLOG_MODEL_INTERPRETATION: 'QLOG_MODEL_INTERPRETATION',
  QLOG_WELL_SIMULATION: 'QLOG_WELL_SIMULATION',
  WELL_INGESTION: 'WELL_INGESTION',
  MODEL_TRAINING: 'MODEL_TRAINING',
  QDRILL_HPMA: 'QDRILL_HPMA',
  QDRILL_ROP_OPTIMIZER: 'QDRILL_ROP_OPTIMIZER',
  QDRILL_INGESTION: 'QDRILL_INGESTION',
  MULTI_SIMULATE_SEISMIC: 'MULTI_SIMULATE_SEISMIC',
  MULTI_MODEL_TRAINING: 'MULTI_MODEL_TRAINING',
  QEARTH_FEATURE_ENGINEERING: 'QEARTH_FEATURE_ENGINEERING',
  BULK_WELL_INGESTION: 'BULK_WELL_INGESTION'
};
